<template>
    <el-card>
        <div class="content-header">
            <el-row :gutter="5">
                <el-col :span="24" style="text-align:left;">
                    <div class="searchblock">
                        <el-button type="primary" size="small" @click="SelectMerchant"
                            style="margin-right:10px;">商户</el-button>
                        <el-select v-model="status" style="margin-right:10px;width:100px;" size="small">
                            <el-option label="全部" value="1"></el-option>
                            <el-option label="已上架" value="2"></el-option>
                            <el-option label="已下架" value="3"></el-option>
                            <el-option label="库存不足" value="4"></el-option>
                        </el-select>
                        <el-cascader :options="option" v-model="categoryid" placeholder="选择品类" clearable
                            style="margin-right:10px;width: 100px;" :show-all-levels="false" :props="props"
                            @change="SetBrand" size="small"></el-cascader>
                        <el-select v-model="brandid" size="small" placeholder="选择品牌" filterable
                            style="margin-right:10px;width:110px" clearable :disabled="categoryid ? false : true"
                            @change="SetModel" value-key="brand.ID">
                            <el-option v-for="brand in brands" :key="brand.ID" :label="brand.Brand"
                                :value="brand.ID"></el-option>
                        </el-select>
                        <el-select v-model="modelid" placeholder="选择型号" filterable style="margin-right:10px;width:120px"
                            clearable :disabled="brandid ? false : true" :loading="loading" @visible-change="GetModel"
                            size="small" value-key="model.ID">
                            <el-option v-for="model in models" :key="model.ID" :label="model.Model"
                                :value="model.ID"></el-option>
                        </el-select>
                        <el-input v-model="keyword" placeholder="套餐编码/套餐标题/商品编码" prefix-icon="el-icon-search"
                            style="width:210px" size="small"></el-input>
                        <el-button type="primary" @click="init" style="margin-left:10px;" size="small">搜索</el-button>
                        <el-button type="success" @click="add" size="small">添加</el-button>
                        <!-- <el-button type="info" @click="dataexport" disabled size="small">导出</el-button> -->
                        <el-button type="warning" @click="BatchEditPdt" size="small">批量操作</el-button>
                        <el-button type="warning" @click="onekeycopy" size="small">一键复制</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border :max-height="viewportHeight" style="width: 100%; margin: 1em 0"
                v-loading="load" element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                :default-sort="{ prop: 'AddDTime', order: 'descending' }" @selection-change="CheckSelection"
                size="small">
                <el-table-column type="selection" width="40" />
                <el-table-column label="商品编码" width="120" prop="PdtCode">
                    <template #default='scope'>
                        <span>{{ scope.row.PdtCode }}:{{ scope.row.ID }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="PdtName"></el-table-column>
                <el-table-column label="商户名称" width="100" prop="Merchant">
                    <template #default="scope">
                        <span v-if="scope.row.Merchant">{{ scope.row.Merchant }}</span>
                        <span v-else>深圳市闲牛科技有限公司</span>
                    </template>
                </el-table-column>
                <el-table-column label="品类" width="80" prop="Category"></el-table-column>
                <el-table-column label="品牌" width="60" prop="Brand"></el-table-column>
                <el-table-column label="型号" width="80" prop="Model"></el-table-column>
                <el-table-column label="套餐数量" width="60" prop="Plancount">
                    <template #default="scope">
                        <span>{{ scope.row.Up }} / </span>
                        <span v-if="!scope.row.Plancount">0</span>
                        <span v-else>{{ scope.row.Plancount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="总库存" width="60" prop="TotalStock">
                    <template #default="scope">
                        <span v-if="!scope.row.TotalStock">0</span>
                        <span v-else>{{ scope.row.TotalStock }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="140" prop="AddDTime" :formatter="Getdate"
                    sortable></el-table-column>
                <el-table-column label="状态" prop="TotalStock" width="60">
                    <template #default="scope">
                        <span v-if="scope.row.Status == 0">已下架</span>
                        <span v-if="scope.row.Status == 1">已上架</span>
                        <span v-if="scope.row.Stock <= 5">库存不足</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.Status == 0" type="text"
                            @click="Enable(scope.row.ID, scope.$index)">上架</el-button>
                        <el-button v-if="scope.row.Status == 1" type="text"
                            @click="Disable(scope.row.ID, scope.$index)">下架</el-button>
                        <!-- <el-button type="text" @click="Edit(scope.row.ID)">编辑</el-button> -->
                        <el-button type="text" @click="Del(scope.row.ID)"
                            v-if="operator == '管理员' ? 'disabled' : false">删除</el-button>
                        <el-button type="text" @click="Copy(scope.row.ID, scope.row.MerchantID)"
                            v-if="ismerchant != 1">复制</el-button>
                        <el-button type="text" @click="Lock(scope.row.ID, scope.$index)"
                            v-if="ismerchant == 1 && scope.row.IsPdtLock == 1">申请解锁</el-button>
                        <el-button type="text" @click="UnLock(scope.row.ID, scope.$index)"
                            v-if="ismerchant == 0 && scope.row.IsPdtLock == 1 && scope.row.IsApply == 1">解锁</el-button>
                        <br>
                        <!-- <el-button type="text" @click="AddPlan(scope.row.ID)">编辑套餐</el-button> -->
                        <!-- <el-button type="text" @click="ShowPlan(scope.row.ID)">查看套餐</el-button> -->
                        <el-button type="text" v-if="scope.row.isagent != 1"
                            @click="QRcode(scope.row.ID)">查看二维码</el-button>
                        <el-button type="text" @click="setPrice(scope.row.ID, scope.row.PdtName)">租完即送自动定价</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="curpage"
                :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                style="text-align:center;" />
        </div>
    </el-card>
    <el-dialog v-model="detail" title="商品信息">
        <div class="description">
            <el-descriptions border :column="3">
                <el-descriptions-item label="商品名">{{ info.PdtName }}</el-descriptions-item>
                <el-descriptions-item label="商品编码">{{ info.PdtCode }}</el-descriptions-item>
                <el-descriptions-item label="品类">{{ info.Category }}</el-descriptions-item>
                <el-descriptions-item label="品牌">{{ info.Brand }}</el-descriptions-item>
                <el-descriptions-item label="型号">{{ info.Model }}</el-descriptions-item>
                <el-descriptions-item label="成品标签">
                    <div v-for="quality in info.Quality" :key="quality" style="display:inline-block;padding:5px;">
                        <el-tag>{{ quality }}</el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="标签">
                    <div v-for="tag in info.Tag" :key="tag" style="display:inline-block;padding:5px;">
                        <el-tag>{{ tag }}</el-tag>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <div>
                <div class="label">商品轮播图</div>
                <el-image v-for="url in info.Imgs" :key="url" :src="url" lazy
                    style="width: 150px; height: 150px; padding: 15px;" :preview-src-list="info.Imgs"></el-image>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="planlist" title="套餐列表" width="60%">
        <el-button type="primary" size="small" @click="BatchEditPlan">批量编辑</el-button>
        <el-divider></el-divider>
        <el-table :data="planlistdata" @selection-change="CheckSelection">
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="套餐编码" prop="PlanCode"></el-table-column>
            <el-table-column label="套餐标题" prop="PlanTitle"></el-table-column>
            <el-table-column label="套餐类型">
                <template #default="scope">
                    <span v-if="scope.row.Plantype == 1">租赁</span>
                    <span v-if="scope.row.Plantype == 2">到期赠送</span>
                    <span v-if="scope.row.Plantype == 3">售卖</span>
                    <span v-if="scope.row.Plantype == 4">定制租赁</span>
                </template>
            </el-table-column>
            <el-table-column label="库存" prop="Stock"></el-table-column>
            <el-table-column label="状态">
                <template #default="scope">
                    <span v-if="scope.row.Status == 0" style="color: rgb(255,25,25)">已下架</span>
                    <span v-if="scope.row.Status == 1">已上架</span>
                    <span v-if="scope.row.Status == 2">库存不足</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button type="text" @click="GotoPlan(scope.row.ID)">跳转至套餐</el-button>
                    <el-button v-if="scope.row.Status == 0" type="text"
                        @click="EnablePlan(scope.row.ID, scope.$index)">上架</el-button>
                    <el-button v-if="scope.row.Status == 1" type="text"
                        @click="DisablePlan(scope.row.ID, scope.$index)">下架</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="confirm" title="批量编辑" center width="20%">
        <el-radio-group v-model="type">
            <el-radio-button :label="1">上架</el-radio-button>
            <el-radio-button :label="2">下架</el-radio-button>
        </el-radio-group>
        <el-divider></el-divider>
        <el-button type="primary" @click="ConfirmBatch">确认</el-button>
        <el-button @click="Cancel">取消</el-button>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="onekey" title="一键复制" width="40%">
        <div class="merchant-block">
            <el-transfer v-model="selagent" filterable filter-placeholder="请输入代理商" :data='agentlist'
                :titles='transfertitle1'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="copyconfirm">确定</el-button>
                <el-button size="small" @click="onekeyfalse">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="copypdt" title="复制产品" width="40%">
        <div class="merchant-block">
            <el-radio-group v-model="copytype" style="margin-bottom:20px;">
                <el-radio-button label="1">仅复制产品</el-radio-button>
                <el-radio-button label="2">复制产品+套餐</el-radio-button>
            </el-radio-group>
            <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmCopy">确定</el-button>
                <el-button size="small" @click="CancelCopy">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="qrcode" title="商品二维码" width="23%" center>
        <div class="qrcode">
            <p style="color:#409eff;">支付宝扫一扫</p>
            <el-image :src="qrcode_img" style="margin:10px;border:solid 1px #c3c0c0"></el-image>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button style="color:#409eff" @click="download">下载</el-button>
            </span>
        </template>
    </el-dialog>
    <!--自动定价-->
    <el-dialog v-model="setprice" title="自动定价" width="60%">
        <el-row class="planb">
            <el-col :span="24">商品名：{{ PdtName }}</el-col>
        </el-row>
        <el-row class="planb">
            <el-col :span="5">选择类型</el-col>
            <el-col :span="5">套餐</el-col>
            <el-col :span="4">成本价</el-col>
            <el-col :span="4">预付尾款</el-col>
            <el-col :span="4">配置</el-col>
            <el-col :span="2">操作</el-col>
        </el-row>
        <span v-if="param.length > 0">
            <el-row class="planb" v-for="(item, index) in param" :key="index">
                <el-col :span="5" class="radioclass">
                    <el-radio-group v-model="item.ispurchase" change="changeradio">
                        <el-radio label="1" size="small">普通</el-radio>
                        <el-radio label="2" size="small">随心购</el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="5"><el-input v-model="item.PlanTitle" :value="item.PlanTitle" placeholder="请填写套餐"
                        clearable /></el-col>
                <el-col :span="4">
                    <el-input-number v-model="item.Margin" :precision="2" :min="0" @change="handleChange1"
                        :value="item.Margin" placeholder="请填写成本价" />
                </el-col>
                <el-col :span="4">
                    <el-input-number v-model="item.Imprest" disabled="false" :precision="2" :min="0"
                        @change="handleChange2" :value="item.Imprest" placeholder="请填写预付尾款" />
                </el-col>
                <el-col :span="4" class="radioclass">
                    <div>{{ item.Params }}</div>
                    <!-- <el-select v-model="item.Params" clearable placeholder="请选择配置">
                <el-option
                    v-for="(item,index) in ValueSet"
                    :key="index"
                    :value="item.value"
                />
            </el-select> -->
                </el-col>
                <el-col :span="2" class="plancenter" v-if="item.delete == 1">
                    <span @click="deleterow(index)">删除</span>
                </el-col>
            </el-row>
        </span>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="AddPlanB" v-if="param.length < 1">新增套餐</el-button>
                <el-button @click="setprice = false">取消</el-button>
                <el-button type="primary" @click="savesetprice">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import constant from "@/constant"
import { saveAs } from "file-saver"
import { ref } from "vue"
export default {
    data() {
        return {
            viewportHeight: ref(0),
            active2: ref('goods'),
            val: ref(""),
            ValueSet: [],
            Margin: 0,
            lastmoney: 0,
            planid: "",
            PdtName: "",
            param: [],
            setprice: ref(false),
            operator: ref(""),
            copypdtid: '',
            copytype: '2',
            copypdt: false,
            selmerchant: [],
            selagent: [],
            transfertitle: ['商户列表', '已选商户'],
            transfertitle1: ['商户列表', '已选商户'],
            merchantlist: [],
            agentlist: [],
            merchant: false,
            load: false,
            curpage: 1,
            pagesize: 10,
            totalcount: "",
            option: [],
            props: {
                label: 'label',
                value: 'value',
                disabled: 'Disabled'
            },
            loading: false,
            nocatesel: true,
            nobrandsel: true,
            type: '',
            confirm: false,
            confirmtype: '',
            selection: [],
            planlist: false,
            planlistdata: [],
            info: '',
            detail: false,
            status: '1',
            tabledata: [],
            keyword: "",
            categoryid: "",
            brandid: '',
            modelid: '',
            thumb: [],
            selectCateData: [],
            selectrows: [],
            qrcode: false,
            qrcode_img: "",
            qrcode_name: '',
            onekey: ref(false)
        }
    },
    mounted() {
        // 监听窗口大小变化事件  
        window.addEventListener('resize', this.handleResize);
        // 初始化时获取一次视口高度  
        this.viewportHeight = (window.innerHeight - 220) + "px";
        console.log(this.viewportHeight)
    },
    beforeUnmount() {
        // 移除事件监听器，防止内存泄漏  
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            // 更新视口高度  
            this.viewportHeight = (window.innerHeight - 220) + "px";
        },
        copyconfirm() {
            console.log(this.selection, this.selagent)
            this.axios.get(constant.saveonekey, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    selection: this.selection,
                    selagent: this.selagent
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.success("复制成功");
                    this.onekey = false
                } else {
                    this.$message.error("复制失败")
                }
                console.log(response)
            })
        },
        onekeyfalse() {
            this.onekey = false
        },
        onekeycopy() {
            console.log(this.selection)
            if (this.selection.length == 0) {
                this.$message.error('请选择要复制的产品')
                return false
            } else {
                this.onekey = true
                this.axios.get(constant.getagent).then((response) => {
                    let list = response.data;
                    for (let i = 0; i < list.length; i++) {
                        let obj = new Object();
                        obj.label = list[i]['Merchant'];
                        obj.key = list[i]['ID'];
                        this.agentlist.push(obj);
                    }
                })
            }
        },
        changeradio(e) {
            console.log(e)
        },
        handleChange1(val) {
            console.log(val)
            this.Margin = val
        },
        handleChange2(val) {
            console.log(val)
            this.Imprest = val
        },
        savesetprice() {
            console.log(this.param)
            for (var i = 0; i < this.param.length; i++) {
                if (!('PlanTitle' in this.param[i]) || this.param[i]['PlanTitle'] == '') {
                    this.$message.error("配置参数不能为空！");
                    return false;
                }
                if (!('Margin' in this.param[i]) || this.param[i]['Margin'] == undefined) {
                    this.$message.error("成本价不能为空！");
                    return false;
                }
                if (!('Params' in this.param[i]) || this.param[i]['Params'] == '') {
                    this.$message.error('配置选项不能为空');
                    return false;
                }
            }
            this.axios.get(constant.savesetprice, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    param: JSON.stringify(this.param),
                    planid: this.planid
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.code == 200) {
                    this.$message.success("设置成功");
                }
                this.setprice = false
            })
        },
        deleterow(indexToDelete) {
            console.log(indexToDelete)
            this.param.splice(indexToDelete, 1)
        },
        AddPlanB() {
            console.log("新增套餐")
            this.param.push({ 'delete': 1, 'Imprest': 0, 'ispurchase': 1, 'Params': '租完归还可买断' })
            console.log(this.param)
        },
        setPrice(id, PdtName) {
            console.log(id)
            this.planid = id
            this.setprice = true
            this.axios.get(constant.get_pdt_plan_url, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data)
                for (var i = 0; i < response.data.length; i++) {
                    response.data[i]['Margin'] = parseInt(response.data[i]['Margin'])
                }
                console.log(response.data)
                this.param = response.data
                this.PdtName = PdtName
            })
            this.axios.get(constant.get_pdt_param).then((response) => {
                console.log(response.data)
                this.ValueSet = response.data
            })
        },
        QRcode(id) {
            console.log(id)
            this.qrcode = true
            this.axios.get(constant.get_qrcode, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response)
                this.qrcode_name = response.data.name
                this.qrcode_img = "https://xuanzj.xianniuzu.com/static/includes/qrcode/" + response.data.name
            })
        },
        download() {
            console.log(this.qrcode_img)
            this.axios.get(this.qrcode_img, {
                responseType: "blob"
            }).then((response) => {
                console.log(response)
                const blob = new Blob([response.data])
                const url = window.URL.createObjectURL(blob)
                saveAs(url, this.qrcode_name)
            })
        },
        Lock(pdtid, index) {
            this.axios.get(constant.lockpdt, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: pdtid
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功，请等待运维解锁");
                    this.tabledata[index].IsPdtLock = 1;
                } else {
                    this.$message.error("操作失败");
                }
            });
        },
        UnLock(pdtid, index) {
            this.axios.get(constant.unlock, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: pdtid
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功");
                    this.tabledata[index].IsPdtLock = 0;
                } else {
                    this.$message.error("操作失败");
                }
            });
        },
        AddPlan(pdtid) {
            const jump = this.$router.resolve({ path: '/addplans', query: { pdtid: pdtid } })
            window.open(jump.href, '_self');
        },
        Copy(pdtid, merchantid) {
            this.selmerchant = [];
            this.copypdtid = pdtid;
            this.copypdt = true;
            console.log(merchantid);
            for (let i = 0; i < this.merchantlist.length; i++) {
                let cur = this.merchantlist[i];
                if (cur.key == merchantid)
                    this.merchantlist.splice(i, 1);
            }
        },
        ConfirmCopy() {
            this.axios.get(constant.copypdt, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    copytype: this.copytype,
                    pdtid: this.copypdtid,
                    selmerchant: JSON.stringify(this.selmerchant)
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功");
                    this.$router.go(0);
                } else {
                    this.$message.error("操作失败");
                }
            });
        },
        CancelCopy() {
            this.copypdt = false;
        },
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back() {
            this.merchant = false;
        },
        SelectMerchant() {
            this.merchant = true;
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        SetBrand() {
            this.brandid = "";
            this.modelid = "";
        },
        SetModel() {
            this.modelid = "";
        },
        GetModel() {
            this.loading = true;
            this.axios.get(constant.get_plan_model_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.categoryid),
                    brand: this.brandid,
                    status: 1,
                }
            }).then((response) => {
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            console.log(cur_date);
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                let hour = ("0" + datetime.getHours()).slice(-2);
                let minute = ("0" + datetime.getMinutes()).slice(-2);
                let second = ("0" + datetime.getSeconds()).slice(-2);
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        Enable(id, index) {
            this.axios.get(constant.enable_pdt_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response);
                if (response.data == "OK") {
                    //this.$router.go(0);
                    this.tabledata[index].Status = 1;
                } else {
                    ElMessage.error({
                        type: 'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id, index) {
            this.$confirm('下架商品时，对应的套餐也会一并下架，您确认要执行操作么？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.get(constant.disable_pdt_url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        //this.$router.go(0);
                        this.tabledata[index].Status = 0;
                    } else {
                        ElMessage.error({
                            type: 'error',
                            message: '操作失败'
                        });
                    }
                });
            }).catch(() => {
                return false;
            });
            return false;
        },
        add() {
            this.$router.push({ path: "/addpdt" });
        },
        init() {
            this.load = true;
            this.axios.get(constant.get_pdt_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    keyword: this.keyword,
                    categoryid: JSON.stringify(this.categoryid),
                    brandid: this.brandid,
                    modelid: this.modelid,
                    status: this.status,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    mid: this.mid,
                    selmerchant: JSON.stringify(this.selmerchant),
                }
            }).then((response) => {
                console.log("返回的数据");
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount * 1;
                this.curpage = response.data.curpage * 1;
                this.load = false;
            });
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        Edit(id) {
            console.log(id);
            //return false;
            if (!id)
                return false;
            const jump = this.$router.resolve({
                path: '/addpdts', query: {
                    id: id, keyword: this.keyword,
                    categoryid: JSON.stringify(this.categoryid),
                    brandid: this.brandid,
                    modelid: this.modelid,
                    status: this.status,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            });
            window.open(jump.href, '_self')
        },
        Del(id) {
            if (!id)
                return false;
            if (!confirm("您确定要删除该产品么？")) {
                return false;
            }
            this.axios.get(constant.del_pdt_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    this.$router.go(0);
                } else {
                    ElMessage.error({
                        type: 'error',
                        message: '操作失败'
                    });
                }
            });
        },
        GetDetail(id) {
            if (!id)
                return false;
            this.axios.get(constant.get_pdt_detail_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            }).then((response) => {
                console.log(response.data);
                this.info = response.data;
                console.log(this.info);
                this.detail = true;
                this.thumb.push(response.data.PdtThumb);
            });
        },
        ShowPlan(id) {
            if (!id)
                return false;
            this.axios.get(constant.get_pdt_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            }).then((response) => {
                console.log(response.data);
                this.planlistdata = response.data;
                this.planlist = true;
            });
        },
        GotoPlan(id) {
            if (!id)
                return false;
            this.$router.push({ path: '/planlist', query: { planid: id } });
        },
        EnablePlan(id, index) {
            if (!id)
                return false;
            this.axios.get(constant.enable_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.planlistdata[index].Status = 1;
                }
            });
        },
        DisablePlan(id, index) {
            if (!id)
                return false;
            this.axios.get(constant.disable_plan_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功");
                    this.planlistdata[index].Status = 0;
                }
            });
        },
        BatchEditPlan() {
            if (this.selection.length <= 0) {
                this.$message.error("请选择要操作的对象");
                return false;
            } else {
                this.confirmtype = 1;//批量处理套餐
                this.confirm = true;
            }
        },
        BatchEditPdt() {
            if (this.selection.length <= 0) {
                this.$message.error("请选择要操作的对象");
                return false;
            } else {
                this.confirmtype = 2;//批量处理产品
                this.confirm = true;
            }
        },
        CheckSelection(rows) {
            this.selectrows = rows;
            this.selection = [];
            if (rows) {
                rows.forEach(row => {
                    this.selection.push(row.ID);
                })
            }
            console.log(this.selection);
        },
        Cancel() {
            this.type = "";
            this.confirm = false;
        },
        ConfirmBatch() {
            if (!this.type) {
                this.$message.error("请选择要执行的操作");
                return false;
            } else {
                this.axios.get(constant.batch_edit_plan, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        type: this.type,//上下架
                        confirmtype: this.confirmtype,//是产品还是套餐
                        ids: JSON.stringify(this.selection),
                    }
                }).then((response) => {
                    console.log(response.data);
                    this.$message.success("操作成功");
                    this.confirm = false;
                    if (this.confirmtype == 1) {
                        //套餐
                        this.selectrows.forEach(row => {
                            if (this.type == 1)
                                row.Status = 1;
                            if (this.type == 2)
                                row.Status = 0;
                        });
                    } else if (this.confirmtype == 2) {
                        //产品
                        this.$router.go(0);
                    }
                });
            }
        }
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        var keyword = this.$route.query.keyword;
        if (keyword)
            this.keyword = keyword;
        var categoryid = this.$route.query.categoryid;
        if (categoryid)
            this.categoryid = JSON.parse(categoryid);
        var brandid = this.$route.query.brandid;
        var modelid = this.$route.query.modelid;
        var status = this.$route.query.status;
        if (status)
            this.status = status;
        var curpage = this.$route.query.curpage;
        if (curpage)
            this.curpage = curpage;
        var pagesize = this.$route.query.pagesize;
        if (pagesize)
            this.pagesize = pagesize;
        console.log("return");
        this.handleResize()
        this.init();
        this.axios.get(constant.get_cascader_url, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response.data);
            this.option = response.data;
        });
        this.axios.get(constant.get_brand_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                status: 1
            }
        }).then((response) => {
            console.log(response.data);
            this.brands = response.data;
            if (this.brands.length > 0) {
                if (brandid)
                    this.brandid = brandid;
            }
        });
        this.axios.get(constant.get_operator_control, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                operatorid: Cookies.get('session')
            }
        }).then((response) => {
            console.log(response.data)
            console.log(this.operator)
            this.operator = response.data[0]['OperatorName']
            console.log(this.operator)
        })
        /*
        this.axios.get(constant.get_tree_url,{
            headers: {
                "Content-Type": "application/json",
            },
            params:{
                status: 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.selectCateData = response.data;
        })*/
        this.axios.get(constant.get_model_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                status: 1
            }
        }).then((response) => {
            console.log(response.data);
            this.models = response.data;
            if (this.models.length > 0)
                if (modelid)
                    this.modelid = modelid;
        });
        this.axios.get(constant.allmerchant, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then((response) => {
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for (let i = 0; i < list.length; i++) {
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
        let pdtid = this.$route.query.pdtid;
        if (pdtid) {
            this.axios.get(constant.getpdtparams, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: pdtid,
                }
            }).then((response) => {
                console.log(response.data);
                this.planinfo.merchantid = response.data.MerchantID;
                this.planinfo.category = response.data.CategoryID;
                this.planinfo.brand = response.data.BrandID;
                let modelid = response.data.ModelID;
                let pdtid = response.data.ID;
                this.axios.get(constant.get_plan_model_url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        category: JSON.stringify(this.planinfo.category),
                        brand: this.planinfo.brand,
                        status: 1,
                    }
                }).then((response) => {
                    console.log(response.data);
                    this.models = response.data;
                    this.planinfo.model = modelid;
                    this.axios.get(constant.get_plan_pdt_url, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        params: {
                            category: JSON.stringify(this.planinfo.category),
                            brand: this.planinfo.brand,
                            model: this.planinfo.model,
                            status: 1,
                            merchantid: this.planinfo.merchantid,
                        }
                    }).then((response) => {
                        this.next = false;
                        this.products = response.data;
                        this.planinfo.pdt = pdtid;
                        this.NextStep();
                    });
                });
            });
        }
    }
}
</script>
<style scoped>
.radioclass {
    display: flex;
    justify-content: center;
    align-items: center;
}

.plancenter {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planb {
    border: 1px solid #abacaf;
    padding: 10px;
    text-align: center;
}

.qrcode {
    text-align: center;
}

.label {
    width: 250xp;
    background: #fafafa;
    padding: 5px;
    margin: 15px 0;
}

.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
